<template>
  <div class="bubbleBox">
    <div ref="Info" class="Bubble" >
      <span class="lj"><span class="tip"></span></span>
      <!-- 巡线_管线 -->
      <div v-if="itemKey == 'view_all_line' && item.tableName == 'patrol_lines'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{item.lineName || '管线名称'}}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管材：{{ item.styleWidthName }}</el-col>
            <el-col :span="24">管材型号：{{ item.materialName }}</el-col>
            <el-col :span="24">公称直径：{{ item.diameterName }}</el-col>
            <el-col :span="24">壁厚：{{ item.thickness }}</el-col>
            <el-col :span="24">实际内径：{{ item.bore }}</el-col>
            <el-col :span="24">长度：{{ item.length }}m</el-col>
            <el-col :span="24">压力级别：{{ startName(item.stressLevel,'stressLevelList') }}</el-col>
            <el-col :span="24">燃气类型：{{ startName(item.gasType,'gasTypeList') }}</el-col>
            <el-col :span="24">建设日期：{{ item.buildDate }}</el-col>
            <el-col :span="24">敷设方式：{{ item.buriedTypeName }}</el-col>
            <el-col :span="24">埋深：{{ item.burialDepth }}</el-col>
            <!-- <el-col :span="24" v-if="item.buriedType == '1'">穿越方式：{{ item.through }}</el-col>
            <el-col :span="24" v-if="item.buriedType == '0'">埋深：{{ item.burialDepth }}</el-col> -->
            <el-col :span="24">状态：{{ startName(item.useStatus,'equipmentStatusList') }}</el-col>
            <el-col :span="24">所属图层：{{ item.coverageName }}</el-col>
            <el-col :span="24" class="bottomBt" v-if="pageStartName == 'line'">
                <el-button plain @click="openDeviceRele(item)" v-if="item.equipmentList&&item.equipmentList.length>0" size="mini">关联设备</el-button>
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'view_all_line')">删除</el-button>
            </el-col>
          </el-row>
        </div>
    </div>
    <div v-if="itemKey == 'view_all_line' && item.tableName != 'patrol_lines'" class="markbox">
        <div class="markTitle">
          <p>
            <span> {{item.pipeName}} </span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管材：{{ item.pipeTypeName }}</el-col>
            <el-col :span="24">管材型号：{{ item.pipeModelName }}</el-col>
            <el-col :span="24">管线长度：{{ item.pipeLength }}m</el-col>
            <el-col :span="24">壁厚mm：{{ item.thickness }}</el-col>
            <el-col :span="24">燃气介质：{{ item.gasTypeName }}</el-col>
            <el-col :span="24">压力级别：{{item.pressureLevelName }}</el-col>
            <el-col :span="24">公称直经：{{ item.diameterName }}</el-col>
            <el-col :span="24">实际内径：{{ item.innerDiameter }}</el-col>
            <el-col :span="24">埋深：{{ item.depth }}</el-col>
            <el-col :span="24">建设类型：{{ item.buildTypeName }}</el-col>
            <el-col :span="24">建设日期：{{ item.buildDate }}</el-col>
            <el-col :span="24">粗糙度：{{ item.roughness }} </el-col>
            <el-col :span="24">下次检测日期：{{ item.nextMonitorDate }}</el-col>
            <el-col :span="24">所在道路：{{ item.road }}</el-col>
            <el-col :span="24">使用状态：{{ item.pipeStatusName }}</el-col>
            <el-col :span="24">确认状态：{{ item.confirmStatusCodeName }}</el-col>
            <el-col :span="24">备注：{{ item.remark }}</el-col>
            <!-- <el-col :span="24" class="bottomBt">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'tb_basic_pipe')">删除</el-button>
            </el-col> -->
          </el-row>
        </div>
    </div>
      <div v-if="itemKey == 'patrol_events_linqing'" class="markbox" style="min-height:auto;">
        <div class="markTitle">
          <p>
            <!-- <span>{{ item.lineName }}</span> -->
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature" >
          <el-row>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <el-col :span="24">事件类型：{{ item.eventCodeIdConfirmName || item.eventCodeName }}</el-col>
            <!-- <el-col :span="24">事件类型：{{ item.eventCodeName }}</el-col> -->
            <el-col :span="24">详细地址：{{ item.eventAddressConfirm ||  item.eventAddress}}</el-col>
            <el-col :span="24">巡线员：{{ item.eventPeopleName }}</el-col>
            <!-- <el-col :span="24">说明：{{ item.event_remark }}</el-col> -->
          </el-row>
        </div>
      </div>
      <div v-if="itemKey == 'patrol_clock'" class="markbox" style="min-height:auto;">
        <div class="markTitle">
          <p>
            <!-- <span>{{ item.lineName }}</span> -->
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature" >
          <el-row>
            <el-col :span="24">打卡点：{{item.clockName}}</el-col>
            <el-col :span="24">地址：{{ item.clockAddress }}</el-col>
            <el-col :span="24" class="bottomBt" v-if="pageStartName == 'district'">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_clock')">删除</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="itemKey == 'linqing_view_user'" class="markbox" style="min-height:auto;">
        <div class="markTitle">
          <p>
            <!-- <span>{{ item.lineName }}</span> -->
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature" >
          <el-row>
            <el-col :span="24">人员名称：{{ item.username }}</el-col>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <!-- <el-col :span="24">最后登录时间：{{ item.updateTime }}</el-col> -->
            <el-col :span="24">手机号：{{ item.mobile }}</el-col>
          </el-row>
        </div>
      </div>
    <div v-else-if="itemKey == 'patrol_networks'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{ item.networkName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管线总长度:
              {{jsL}}
              <!-- {{ (item.length/1000).toFixed(3) }} -->
              km</el-col>
            <el-col :span="24">巡检点数量:{{ item.count }}</el-col>
            <el-col :span="24">创建人：{{item.createBy}}</el-col>
            <el-col :span="24">创建时间：{{item.createTime}}</el-col>
            <el-col :span="24" class="bottomBt" v-if="pageStartName == 'district'">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_networks')">删除</el-button>
            </el-col>
          </el-row>
        </div>
    </div>
    <!-- 本系统 -->
    <div v-else-if="itemKey == 'view_all_point' && system == 'patrol_equipments'" class="markbox">
      <!-- && item.table_name == 'patrol_equipments' -->
        <div class="markTitle">
          <p>
            <span>{{ item.equipmentName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">设备类型:{{ item.equipmentTypeName }}</el-col>
            <el-col :span="24">规格型号：{{ item.equipmentSpecifications }}</el-col>
            <el-col :span="24">设备状态：{{ startName(item.equipmentStatus,'equipmentStatusList')}}</el-col>
            <el-col :span="24">生产日期：{{item.equipmentTime}}</el-col>
            <el-col :span="24">使用日期：{{item.equipmentUseTime}}</el-col>
            <el-col :span="24">所在街道：{{item.equipmentStreet}}</el-col>
            <el-col :span="24">所属图层：{{item.coverageName}}</el-col>
            <el-col :span="24" v-if="item.lineName">关联管线：{{item.lineName}}</el-col>
            <el-col :span="24">生产厂家：{{item.equipmentProducers}}</el-col>
            <el-col :span="24">所在地址：{{item.equipmentHome}}</el-col>
            <el-col :span="24" class="bottomBt" v-if="pageStartName == 'equipment'">
              <el-button type="primary" plain size="mini" @click="printQrcode(item)">下载二维码</el-button>
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'view_all_point')">删除</el-button>
            </el-col>
          </el-row>
        </div>
    </div>
    <!-- Gis数据 -->
    <div v-else-if="itemKey == 'view_all_point' && system != 'patrol_equipments'" class="markbox">
      <!-- && item.table_name == 'patrol_equipments' -->
        <div class="markTitle">
          <p>
            <span>{{ item.equipmentName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">设备类型:{{ item.equipmentTypeName }}</el-col>
            <el-col :span="24">地址:{{ item.equipmentHome }}</el-col>
            <!-- <el-col :span="24">设备编号:{{ item.equipmentTypeName }}</el-col>
            <el-col :span="24">经纬度：{{ item.equipmentSpecifications }}</el-col>
            <el-col :span="24">设备名称：{{ startName(item.equipmentStatus,'equipmentStatusList')}}</el-col>
            <el-col :span="24">阀门种类：{{item.equipmentTime}}</el-col>
            <el-col :span="24">规格：{{item.equipmentUseTime}}</el-col>
            <el-col :span="24">型号：{{item.equipmentStreet}}</el-col>
            <el-col :span="24">埋深：{{item.coverageName}}</el-col>
            <el-col :span="24">高程：{{item.deptName}}</el-col>
            <el-col :span="24">安装日期：{{item.equipmentProducers}}</el-col>
            <el-col :span="24">生产厂家：{{item.equipmentHome}}</el-col>
            <el-col :span="24">设备状态：{{item.equipmentProducers}}</el-col>
            <el-col :span="24">压力级别：{{item.equipmentHome}}</el-col>
            <el-col :span="24">备注：{{item.equipmentHome}}</el-col> -->
            <el-col :span="24" class="bottomBt" v-if="pageStartName == 'equipment'">
              <el-button type="primary" plain size="mini" @click="printQrcode(item)">下载二维码</el-button>
            </el-col>
          </el-row>
        </div>
    </div>


  </div>
    <el-dialog title="查看" :append-to-body="true" :visible.sync="DeviceReleData">
        <div style="max-height:400px;overflow: auto;">
          <el-table  :height="200" :data="item.equipmentList">
            <el-table-column
                prop="equipmentName"
                label="设备名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="equipmentTypeName"
                label="设备类型"
                align="center">
            </el-table-column>
            <el-table-column
                prop="equipmentHome"
                label="位置"
                align="center">
            </el-table-column>
          </el-table>
        </div>
    </el-dialog>
    <AsetQrcodes ref="qrcode"/>
  </div>
</template>
<script>
import { queryFeatrue } from '@/apis/geo'
import { lineDetail,lineDelete,networkDetail,delNetwork,equipmentsDetail,delEquipments,lineGet,equipmentDetali,getClock,delClock} from '@/apis/commonType'
import {
  eventPage,getPersonDetail
} from "@/RequestPort/maintenance";
import AsetQrcodes from "@/components/AsetQrcodes";
import {serverMap} from "@/views/Gis/utils/dict"
import { constructionTeam_relationDept } from '../../../RequestPort/apply/team';
import { formatLocation } from '../../Gis/utils/geoTool';
import {getLength} from "../../../RequestPort/maintenance/task";
export default {
  components: {AsetQrcodes},
  props: {
    layerName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      DeviceReleData:false,
      overlay: null,
      infoWindow: null,
      isInfo: true,
      item: {},
      layerType: 'point',
      itemKey: '',
      equipmentStatusList:[],
      gasTypeList:[],
      stressLevelList:[],
      pageStartName:'',
      system:'',
      jsL:'0'
    }
  },
  destroyed() {
    this.$eventBus.$off("patrolLinesfun");
    this.$eventBus.$off("pageStart");
    this.$eventBus.$off("RemoveArea");
    this.$eventBus.$off("RemovepopUp");
  },
  mounted() {
    this.$eventBus.$on("patrolLinesfun", (data) => {
      // console.log(data)
      this.type=data.type
      // let addForm=JSON.parse(JSON.stringify(data.data)) 
      // let start = data.type == 'view_all_line' ?  'linqing:view_all_line' : ''
      if(data.start == 'linqing:patrol_networks'){
        this.itemKey = 'patrol_networks'
        this.getNetworkDetail(data.data,data.lnglat)
      } else if(data.start == 'linqing:view_all_line'){
        this.itemKey = 'view_all_line'
        this.getLineDetail(data.data,data.lnglat)
      }
      else if(data.start == 'linqing:patrol_clock'){
        this.itemKey = 'patrol_clock'
        this.openFeatrueInfo(data.data,data.lnglat)
      }
      else if(data.start == 'linqing:view_all_point'){
        this.itemKey = 'view_all_point'
        this.getEquipmentsDetail(data.data,data.lnglat)
      }
      else {
        this.getFeatrue(data.lnglat,data.start)
      }
    });
    // 压力级别
    this.stressLevelList = serverMap[1006]
    // 燃气类型
    this.gasTypeList = serverMap[1008]
    // 管线状态
      this.equipmentStatusList = serverMap[1007]
    this.$eventBus.$on("pageStart", (data) => {
      this.pageStartName=data
      
    });
    this.$eventBus.$on("RemoveArea", (data) => {
      this.close()
    });
    this.$eventBus.$on("RemovepopUp", (data) => {
    if(this.infoWindow){
      this.infoWindow.close()
      }
    });
  },
  methods: {
    //批量打印二维码
    printQrcode(item){
      //调用子组件打印
      let arr = []
        arr.push({id:item.id+',' +item.tableName,name:item.equipmentName ? item.equipmentName : 'GIS设备'})
      this.$refs.qrcode.printQrCode(arr)
    },
     startName2(val,name){
      for(let i=0;i<this[name].length;i++){
        if(this[name][i].id == val) {
          return this[name][i].name
        }
      }
    },
    startName(val,name){
      for(let i=0;i<this[name].length;i++){
        if(this[name][i].dictCode == val) {
          return this[name][i].name
        }
      }
    },
    openDeviceRele(){
      this.DeviceReleData = true
    },
    /**
     * 编辑
     */
    editFun(item){
      this.$parent.$parent.closePDraw()
      let data={
        data:item,
        type:this.itemKey
      }
      if(this.itemKey != 'patrol_networks') {
      this.$eventBus.$emit('xfSwitch',true)
      }
      // 打开图层
      if(this.itemKey == 'patrol_networks') {
        this.$eventBus.$emit('coverageOpenTWO')
      }
      this.$eventBus.$emit('vectorEdit', data )
      // 关闭
      if(this.infoWindow){
        this.infoWindow.close()
      }
    },
    delFun(id,type){
      this.$confirm(`确定要删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {

          if(type=='view_all_line'){
            let p = {
                id: id
              }
              lineDelete(p).then(res => {
                if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.reloadList()
                  this.$parent.$parent.getListArea()
                }
              })
          }else if(type=='patrol_networks'){
            let p = {id: id }
            delNetwork(p).then(res => {
               if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.SearchLineDataChange()
                }
            })
          }else if(type=='view_all_point'){
            let p = {id: id }
            delEquipments(p).then(res => {
               if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.getList()
                }
            })
          } else if(type == 'patrol_clock'){
            let p = {id: id }
            delClock(p).then(res => {
               if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.SearchLineDataChange()
                }
            })
          }
      })
       
    },
    initialize(e) {
      // console.log(e)
      this.item = e.item
      this.infoWindow = e.infoWindow
      
      if(this.itemKey == 'patrol_networks'){
         this.seeKm(this.item)
      }
    },
    seeKm(row){
      getLength({id:row.id}).then(res => {
        if(res.code == 200) {
          this.jsL = res.data
        }
      })
      // let coverage=row.coverage
      // let network=row.location
      // let pos = []
      // network.forEach(element => {
      //   pos.push(element[0] + ' ' + element[1])
      // })
      // pos.push(pos[0])
      // let posStr = pos.join(',')
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }


     
      
      // let layersVue1 = coverage
      // let params = {
      //   SERVICE: 'wfs',
      //   VERSION: '1.0.0',
      //   REQUEST: 'GetFeature',
      //   typeName: 'linqing:view_all_line_q',
      //   outputFormat: 'application/json',
      //   viewparams: `netid:${row.id}`,
      //   CQL_FILTER:
      //       "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
      //   }
      //   if (!layersVue1) {
      //    params.CQL_FILTER = '1=2'
      //   }  
      //    const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });      
      // queryFeatrue(params).then(res => {//调取管线
      //    loading.close();
        
      //   // this.total = res.totalFeatures
      //   let f = res.features
      //   let line=[]
      //     f.forEach(item => {
      //       let location=formatLocation(item.properties.location_str,'LineString')      
      //       console.log(location)
            
      //            for(let i=1;i<location.length;i++){
                 
      //              let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
      //              let sub1=[Number(location[i][0]),Number(location[i][1])]
                
      //             line.push([sub1,sub])
      //            }
                 
          
          
      //   })


      //     let py=network
      //    // py.push(py[0])//首尾相接
      //     let allLength=0
      //      line.forEach(item => {//计算长度
      //         var line1 = turf.lineString(item);
      //         var line2 = turf.lineString(py);
      //         var intersects = turf.lineIntersect(line1, line2);  
      //         if(intersects.features.length<1){//没相交是面内
      //             let pn = turf.polygon([py]);                 
      //             let isHave=turf.booleanContains(pn, line1);
      //             if(isHave){                   
      //                 let length = turf.length(line1, {units: 'kilometers'});
      //                 allLength=allLength+length
      //             }                
      //         }else if(intersects.features.length>0){//一条线相交了两次及以上
      //           var line = turf.lineString(item);
      //           var splitter = turf.lineString(py);
      //           var splitline = turf.lineSplit(line, splitter);     
      //           splitline.features.forEach(fea => {     
      //             let feaLine=  fea.geometry.coordinates
      //             let pn = turf.polygon([py]);
      //             let pl = turf.lineString(feaLine);
      //             let isHave=turf.booleanContains(pn, pl);
      //             if(isHave){
      //               let l=turf.lineString(feaLine)
      //               let length = turf.length(l, {units: 'kilometers'});
      //               allLength=allLength+length
      //             }
      //           }); 
      //         }
      //     })
      
      //    allLength>0?this.jsL=allLength.toFixed(3):this.jsL='0'
      // })

    },
    //关闭
    close() {
      // 片区取消高亮
      if(this.$parent.$parent && this.pageStartName == 'district') {
        this.$parent.$parent.close()
      }
      if(this.infoWindow){
      this.infoWindow.close()
      }
    },
    getFeatrue(lnglat,start) {
      this.$eventBus.$emit('vectorEdit',{type:''} )
      this.$eventBus.$emit('vectoradd',false )
      const that = this
      let points = that.centerPointGetFourPoint(lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let surface = start ? start : 'linqing:lineWalking'
       let cqlFilter = this.$store.getters.filterAllTree
       let cql
      if (cqlFilter) {
        cql = { CQL_FILTER: cqlFilter }
      }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: surface,
        LAYERS: surface,
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 10,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
         ...cql,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        propertyName: 'id,table_name,tableName'
      }
      queryFeatrue(parames).then(res => {
        if (res.features && res.features[0]) {
          let patrolEvents=[]
          let patrolEquipments=[]
          let patrolLines=[]
          let patrolClock =[]
          let patrolNetworks=[]
          let patrolPeople = []
           let features=res.features
           features.forEach(item => {
            let id=item.id
            let arr=id.split('.')
            if (arr[0] == 'patrol_events_linqing'){
              patrolEvents.push(item.properties)
              return
            }
            // 巡线-打卡点/设备
            else if(arr[0] == 'view_all_point') {
              patrolEquipments.push(item.properties)
              return
            }else if(arr[0] == 'patrol_lines') {
              patrolLines.push(item.properties)
              return
            }
            // 打卡点
            else if(arr[0] == 'patrol_clock') {
              patrolClock.push(item.properties)
              return
            }
            // 巡线_管线
            else if(arr[0] == 'view_all_line') {
              patrolLines.push(item.properties)
              return
            }else if(arr[0] == 'patrol_networks') {
              patrolNetworks.push(item.properties)
              return
            }else if(arr[0] == "linqing_view_user"){
              patrolPeople.push(item.properties)
              return
            }
        })
          if(patrolEvents.length>0) {
            //事件
             this.itemKey = 'patrol_events_linqing'
            this.pipeDetail(patrolEvents[patrolEvents.length-1], lnglat)
          }else if(patrolEquipments.length>0) {
            //巡线-设备/打卡点
             this.itemKey = 'view_all_point'
             that.getEquipmentsDetail(patrolEquipments[patrolEquipments.length-1], lnglat)
          }else if(patrolLines.length>0) {
            //巡线——管线
             this.itemKey = 'view_all_line'
             that.getLineDetail(patrolLines[patrolLines.length-1], lnglat)
          }else if(patrolPeople.length>0) {
            this.itemKey = 'linqing_view_user'
            that.peopleDetail(patrolPeople[patrolPeople.length-1], lnglat)
          }
          // 打卡点
          else if(patrolClock.length>0) {
            this.itemKey = 'patrol_clock'
            that.patrolClockDetail(patrolClock[0], lnglat)
          }
          else if(patrolNetworks.length>0) {
            // 判断有值的情况打算关闭高亮
            if(this.$parent.$parent && this.pageStartName == 'district' ){
              this.$parent.$parent.checkhighlight()  
            }
            //片区
             this.itemKey = 'patrol_networks'
             that.getNetworkDetail(patrolNetworks[0], lnglat)
          }
        }
      })
    },
    // 设置偏移量
    openFeatrueInfo(feat, lnglat) {
      let html = this.$refs.Info
      let infoWindow = new AMap.InfoWindow({
        position: lnglat,
        offset: new AMap.Pixel(-2, -15),
        anchor: 'top-left',
        autoMove:true,
        content: html,
        avoid: [-100, 20, 100, 20]
      })
      this.initialize({
        item: feat || {},
        infoWindow: infoWindow
      })
      infoWindow.open(this.$parent.map)
      // console.log(lnglat)
    //  this.$parent.map.setCenter([lnglat.lng,lnglat.lat])
    },
    /**
     * 巡线——管线
     */
    getLineDetail(data, lnglat) {
      let p = {
        tableName:data.tableName,
        id: data.id // data.item_key
      }
      // lineDetail(p).then(res => {
      //   this.openFeatrueInfo(res.data.records[0], lnglat)
      // })
      lineGet(p).then(res => {
        this.openFeatrueInfo(res.data, lnglat)
      })
    },
    /**
     * 打卡点
     */
    patrolClockDetail(data,lnglat) {
      let p = {
        id: data.id // data.item_key
      }
      getClock(p).then(res => {
        this.openFeatrueInfo(res.data, lnglat)
      })
    },
    /**
     * 片区详情
     */
    getNetworkDetail(data, lnglat) {
      let p = {
        id: data.id // data.item_key
      }
      networkDetail(p).then(res => {
        this.openFeatrueInfo(res.data, lnglat)
      })
    },

    pipeDetail(data,lnglat){
      eventPage({ids:data.id}).then((e)=>{
        this.openFeatrueInfo(e.data.records[0], lnglat)
      })
    },
    /**
     * 人员
     */
    peopleDetail(data,lnglat){
      getPersonDetail({userId:data.id}).then((e)=>{
        this.openFeatrueInfo(e.data.records[0], lnglat)
      })
    },
    /**
     * 巡线设备/打卡点
     */
    getEquipmentsDetail(data, lnglat) {
      
      if(data.table_namees) {
      this.system = data.table_namees
      } else {
        this.system = data.tableName
      }
      let p = {
        tableName:data.tableName,
        id: data.id // data.item_key
      }
      equipmentDetali(p).then(res => {
        this.openFeatrueInfo(res.data[0], lnglat)
      })
    },
    centerPointGetFourPoint(lnglat) {
      let margin = 100
      let Pixel = this.$parent.map.lngLatToContainer(lnglat)
      let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
      const newLnglat = this.$parent.map.containerToLngLat(newPixel)

      let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

      const centerPoint = lnglat
      const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
      const upRightPoint = centerPoint.offset(parseInt(side / 2), parseInt(side / 1.9))
      const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
      const rightBottomPoint = centerPoint.offset(parseInt(side / 2), -parseInt(side / 1.7))
      return {
        upLeftPoint: [upLeftPoint.lng, upLeftPoint.lat],
        upRightPoint: [upRightPoint.lng, upRightPoint.lat],
        leftBottomPoint: [leftBottomPoint.lng, leftBottomPoint.lat],
        rightBottomPoint: [rightBottomPoint.lng, rightBottomPoint.lat]
      }
    },
    
  }
}
</script>
<style lang="scss">
.amap-info-outer,
.amap-menu-outer {
  box-shadow: none !important;
}
.amap-info-content{padding:0;background: none;overflow: visible;}
.top-left .amap-info-sharp{display: none;}
.amap-info-close{display: none;}
</style>
<style lang="scss" scoped>
.bottomBt{margin-top:15px;text-align: center;}
.line-mark {
  width: 4rem;
}
.bubbleBox {
  position: absolute;
  z-index: -1;
}
.Bubble {
  margin-top: -10px;
  div {
    font-size: 14px;
  }
  
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.15rem;
    left: -18px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--background-color-skin);
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -10px;
      z-index: -1;
      border-left: 10px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #66b1ff;
    }
  }
}


.markbox {
  background: var(--background-color-skin);
  border: 1px solid #66b1ff;
  min-width: 2.62rem;
  // min-height: 2.67rem;
  color: var(--font-color-skin);
  // background-size: 100% 100%;
  font-size: 0.16rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markbox.min {
  min-width: 2.5rem;
  width: 2.5rem;
}
.tt {
  color: #01d8ff;

}
::v-deep {
    .el-divider {
      background-color: #01d8ff;
      margin-left: 0;
    }
  }
.markTitle {
  height: 0.42rem;
  display: flex;
  line-height: 1;
  justify-content: space-between;
  align-items: center;
  background: rgba(1, 216, 255, 0.1);

  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.2rem;
    color: #409eff;
    img {
      margin-right: 0.1rem;
      height: 0.16rem;
      width: auto;
    }
  }
  i {
    font-size: 0.2rem;
    color: #679db4;
    cursor: pointer;
    margin-right: 0.1rem;
  }
  i:hover {
    color: #548aa1;
  }
}

.Feature {
  margin: 0 0.2rem;
  line-height: 0.28rem;
  margin-top: 0.08rem;
  margin-bottom: 0.12rem;
  width: 3rem;
}
</style>
