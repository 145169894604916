<template>
  <div id="qrCodeDiv" style="display:none;">
    <!-- 使用css样式对生成的二维码打印时定义了分页 -->
    <div v-for="(qrCode,index) in qrCodeList" :key="index" style="width: 25%;float: left;justify-content: center;">
      <!-- 这个div承载了每个二维码实例,必须定义 -->
      <!-- <div :id="'qrCode'+ index"></div> -->
        <div :id="'qrCode' + index" class='codePic' style="display:none;"></div>
        <canvas :id='"canvas" + index' style="display:none;"></canvas>
        <!-- <div :id="'newImage' + index" > </div> -->
      <!-- 自定义内容 -->
      <div style="margin: 10px 0;">{{qrCode.name}}({{qrCode.code}})</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import JSZip from "jszip"
import FileSaver from 'file-saver'
export default {
  data() {
    return {
      /* 二维码扫描出的访问地址  http://XXX */
      qrCodeList:[]
    }
  },
  methods:{
    // printQrCode(qrCodeList){
    //   for (let index = 0; index < this.qrCodeList.length; index++) {
    //     const domId = "qrCode" + index;
    //     document.getElementById(domId).innerHTML = "";
    //   }
    //   this.qrCodeList = qrCodeList;
    //   this.$nextTick(() => {
    //     let imgZip = []
    //     for (let index = 0; index < this.qrCodeList.length; index++) {
    //       const id = this.qrCodeList[index].id;
    //       this.qrCode('qrCode' + index,id);
    //       // //找到canvas标签
    //       let myCanvas = document.getElementById('qrCode' + index).getElementsByTagName('canvas');
    //       // //设置a标签的href属性（将canvas变成png图片）
    //       let obj={
    //         imgSrc:myCanvas[0].toDataURL('image/jpg'),
    //         name:this.qrCodeList[index].name
    //       }
    //       imgZip.push(obj)
    //     }
    //     const zip = new JSZip();  // 压缩的插件方法
    //     const cache = {};
    //     imgZip.forEach((item) => {
    //       const fileName = item.name;
    //       zip.file(`${fileName}.png`, item.imgSrc.substring(22), { base64: true });
    //       cache[fileName] = item.imgSrc;
    //     });
    //     // 打包
    //     zip.generateAsync({ type: 'blob' }).then((content) => {
    //       FileSaver.saveAs(content, '二维码.zip');//这里设置压缩包的名称
    //     })
    //   })
    // },
    awaitTime(t){
      return new Promise((a) => setTimeout(() =>  a() , t) )
    },
    async printQrCode(qrCodeList) {
      this.qrCodeList = qrCodeList
      await this.awaitTime(200)
      let imgZip = []
      for (let index = 0; index < this.qrCodeList.length; index++) {
        const id = this.qrCodeList[index].id;
        this.qrCode("qrCode" + index, id)
        await this.awaitTime(50)
        var canvas = document.getElementById("canvas"+index);
        var ctx = canvas.getContext("2d");
        // 生成合成的二维码的长高；
        canvas.width = 150;
        canvas.height = 150;
        // 合成二维码背景
        ctx.fillStyle = '#fff'
        ctx.fillRect(0,0,150,150)
        let qrcode = document.getElementById('qrCode' + index)
        var qrcodeImg = qrcode.lastChild;
        // 绘制二维码图片
        ctx.drawImage(qrcodeImg,10,10,130,130);        
        // // 将URL 放到Img 里
        // newImage.appendChild(imgNew);     
        let obj={
          imgSrc: canvas.toDataURL('image/jpg'),
          name:this.qrCodeList[index].name
        }
        imgZip.push(obj)
      }
      const zip = new JSZip();  // 压缩的插件方法
      const cache = {};
      imgZip.forEach((item) => {
        const fileName = item.name;
        zip.file(`${fileName}.png`, item.imgSrc.substring(22), { base64: true });
        cache[fileName] = item.imgSrc;
      });
      // 打包
      zip.generateAsync({ type: 'blob' }).then((content) => {
        FileSaver.saveAs(content, '二维码.zip');//这里设置压缩包的名称
      })
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: contentType});
    },
    /* 构建二维码 */
    qrCode(id,content,w,h){
      new QRCode(id, {
        width: w || 124,
        height: h || 124,
        text: content,  // 二维码内容
        // render: 'canvas' , // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        background: '#f0f', // 背景色
        foreground: '#ff0' // 前景色
      })
    }
  }
}
</script>